@tailwind base;
@tailwind components;
@tailwind utilities;

/* We do not import tailwindcss/base as it interferes with the standard Ionic styling */
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

ion-button {
  margin: 0;
  --box-shadow: none;
}

ion-card {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

ion-item {
  --inner-padding-top: 0.5rem;
  --inner-padding-bottom: 0.5rem;
}

.completeAlert {
  --backdrop-opacity: 1;
  --min-width: 50%;
}
